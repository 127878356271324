import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

@Options({
    name: 'input-dropdown-component',
    props: {
        width: {
            type: [Number, String],
            required: true
        },
        colSize: {
            type: [Number, String],
            default: '12'
        },
        data: {
            type: Array,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String
        },
        placeholder: {
            type: String
        }
    }
})

export default class InputDropdownComponent extends Vue {
    public data: any = [];
    public searchWord = '';
    public searchArray = [];
    public isDropdownHidden = false;
    public disabled = false;

    public toggleDropdown() {
        if (!this.disabled) {
            if (this.isDropdownHidden) {
                this.isDropdownHidden = false;
            } else {
                this.isDropdownHidden = true;
                this.searchArray = this.data;
            }
        }
    }

    public mounted() {
        document.addEventListener('click', this.onClickOutside);
    }

    public unmounted() {
        document.removeEventListener('click', this.onClickOutside);
    }

    public onClickOutside($event: any) {
        if ((this.$el.contains($event.target) || ($event.target.parentElement === this.$el.parentElement))
            && !this.disabled) {
            this.isDropdownHidden = true;
        } else {
            this.isDropdownHidden = false;
        }
    }

    public searchData() {
        this.searchWord = this.searchWord.toUpperCase();
        this.searchArray = this.data.filter((item: ISearchSelect) => {
            return item.value.toUpperCase().indexOf(this.searchWord) !== -1;
        });
    }

    public selectItem(item: ISearchSelect) {
        this.searchWord = item.value;
        this.$emit('selectItem', item.id);
        this.isDropdownHidden = false;
    }

    public reset() {
        this.searchWord = '';
    }

}