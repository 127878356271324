import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';
import {container} from 'tsyringe';
import {ImeiTariffServiceSandbox} from '@/pages/imei-tariff-service/sandbox/imei-tariff-service.sandbox';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import OrganizationTableComponent
    from '@/pages/search-offices-classifier-service/components/organization-table/organization-table.component.vue';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'imei-tariff-service-component',
    components: {
        ServiceNameComponent,
        ServiceSubtitleComponent,
        OrganizationTableComponent,
    }
})

export default class SearchOfficesClassifierServiceComponent extends mixins(Router) {
    private sandbox = container.resolve(ImeiTariffServiceSandbox);
    private store = store;
    public locale$: any = locale;
    public locale: any = null;

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.searchOfficesClassifier) {
                this.locale = res.searchOfficesClassifier;
            }
        });
    }

    public mounted() {
        this.getServiceData();
    }


}
