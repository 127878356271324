<style src="./table.component.scss" lang="scss"> </style>

<template>
  <div class="table">
    <table>
      <slot></slot>
    </table>
  </div>
</template>

<script src="./table.component.ts"></script>
