import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'not-data-component',
})

export default class NotDataComponent extends Vue {

}
