import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {IManufacturer} from '@/pages/imei-tariff-service/types/manufacturer';

@Options({
    name: 'dropdown-manufacturer-component',
    props: {
        manufacturer: {
            type: Array,
            required: true
        },
    }
})

export default class DropdownManufacturerComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public allManufacturer = false;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.imeiTariff) {
                this.locale = res.imeiTariff;
            }
        });
    }

    public onClickOutside($event: any) {
        if(this.$el.contains($event.target) || ($event.target.parentElement === this.$el.parentElement)) {
            this.$emit('onClickOutside', true)
        } else {
            this.$emit('onClickOutside', false)
        }
    }

    public mounted() {
        document.addEventListener('click', this.onClickOutside);
    }

    public unmounted() {
        document.removeEventListener('click', this.onClickOutside);
    }

    public manufacturerClick(info: IManufacturer): void {
        this.$emit('manufacturerClick', info);
    }
}
