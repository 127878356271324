<style src="./input-dropdown.component.scss" lang="scss"></style>

<template>
  <div class="input-dropdown" :class="{'input-dropdown__active':isDropdownHidden}">
    <label class="v-label  mb-4">{{ label }}</label>
    <div class="input-dropdown__input">
      <input
          :placeholder="placeholder"
          @input="searchData($event)"
          @click="toggleDropdown"
          type="text" :disabled="disabled" class="v-input" v-model="searchWord"/>
      <span @click.stop="toggleDropdown"></span>
    </div>
    <div class="input-dropdown__list"
         v-if="isDropdownHidden"
         :style="{width:width}"
    >
      <div class="row">
        <div class="col-12 p-0" :class="[ `col-lg-${colSize}`]">
          <div class="input-dropdown__list-item"
               @click="selectItem(item)"
               v-for="(item , index) in searchArray"
               :key="index"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./input-dropdown.component.ts"></script>