import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import TableComponent from '@/core/shared/table/table.component.vue';
import {container} from 'tsyringe';
import {store} from '@/store';
import DropdownManufacturerComponent
    from '@/pages/imei-tariff-service/components/dropdown-manufacturer/dropdown-manufacturer.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {
    SearchOfficesClassifierServiceSandbox
} from '@/pages/search-offices-classifier-service/sandbox/search-offices-classifier-service.sandbox';
import OrganizationMobileComponent
    from '@/pages/search-offices-classifier-service/components/organization-mobile/organization-mobile.component.vue';
import InputDropdownComponent from '@/core/shared/input-dropdown/input-dropdown.component.vue';

@Options({
    name: 'organization-table-component',
    components: {
        TableComponent,
        DropdownManufacturerComponent,
        NotDataComponent,
        OrganizationMobileComponent,
        InputDropdownComponent
    }
})

export default class OrganizationTableComponent extends Vue {
    private sandbox = container.resolve(SearchOfficesClassifierServiceSandbox);
    public organizationCode: number | string | null = null;
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public manufacturer: string | null = null;
    public manufacturerId: number | null = null;
    public model: string | null = '';
    public pageNumber = 1;
    public pageSize = 10;
    public refreshPagination = false;
    public $refs!: {
        selectOrganization: {
            reset: () => void;
        },
        pagination: {
            reset: () => void;
        },
        paginationMobile: {
            reset: () => void;
        },
    };

    public validation = {
        category: false,
        organization: false
    };

    public determineNumber(index: number) {
        if (this.pageNumber === 1) {
            return index + 1;
        } else if (this.pageNumber > 1) {
            return (((this.pageNumber - 1) * this.pageSize) + (index + 1));
        }
    }

    get category() {
        return this.store.getters._getOrganizationType;
    }

    get organization() {
        return this.store.getters._getOrganizationSubtype;
    }

    get organizationInfo() {
        return this.store.getters._getOrganizationInfo;
    }

    get totalCountOrganizationInfo() {
        return this.store.getters._getOrganizationInfoTotalCount;
    }

    public selectOrganization(info: any) {
        this.organizationCode = info;
        this.resetPagination();
        this.sandbox.getOrganizationInfo({
            organizationCode: this.organizationCode,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
        });
    }

    public selectOrganizationCategory(info: any) {
        this.sandbox.getOrganizationSubtype(info);
        this.$refs.selectOrganization.reset();
        this.store.commit('SET_RESET_ORGANISATION_INFO');
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.searchOfficesClassifier) {
                this.locale = res.searchOfficesClassifier;
            }
        });
        this.sandbox.getOrganizationType();
    }

    public resetPagination() {
        if (this.pageNumber > 1) {
            this.$refs.pagination.reset();
            this.$refs.paginationMobile.reset();
            this.pageNumber = 1;
        }
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        this.sandbox.getOrganizationInfo({
            organizationCode: this.organizationCode,
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
        });
        window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }

    public unmounted() {
        this.store.commit('SET_RESET_ORGANISATION_INFO');
    }
}
