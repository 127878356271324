import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class ImeiTariffServiceSandbox {
    private store = store;

    constructor() {
    }

    getManufacture(): void {
        this.store.dispatch('getManufacture');
    }

    getModelsByManufacturer(payload: any): void {
        this.store.dispatch('getModelsByManufacturer', payload);
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

}
