import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class SearchOfficesClassifierServiceSandbox {
    private store = store;

    constructor() {
    }

    getOrganizationType(): void {
        this.store.dispatch('getOrganizationType');
    }

    getOrganizationSubtype(payload:number): void {
        this.store.dispatch('getOrganizationSubtype', payload);
    }

    getOrganizationInfo(payload:any): void {
        this.store.dispatch('getOrganizationInfo',payload);
    }
}
