<style src="./dropdown-manufacturer.component.scss" lang="scss"></style>

<template>
  <div class="dropdown-manufacturer">
    <div class="row">
      <div class="col-12">
        <div class="dropdown-manufacturer__title">
          {{ locale.popularManufacturers }}
        </div>
      </div>
      <div class="col-12">
        <div class="dropdown-manufacturer__wrapper">
          <div class="row px-7">
            <template v-for="(item) in manufacturer">
              <div class=" col-12 col-lg-4 px-7 mb-12" v-if="item.priority > 0">
                <div
                    @click="manufacturerClick(item)"
                    class="dropdown-manufacturer__item">
                  {{ item.name }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12 mt-20">
        <div class="dropdown-manufacturer__title d-flex dropdown-manufacturer__title-all"
             @click="allManufacturer=!allManufacturer">
          {{ locale.allManufacturers }}
          <div
              :class="{'dropdown-manufacturer__all-active':allManufacturer}"
              class="dropdown-manufacturer__all ml-25">
            <img src="@/assets/images/icons/select-down.svg"
                  alt="select">
          </div>
        </div>
      </div>
      <div class="col-12" v-if="allManufacturer">
        <div class="dropdown-manufacturer__wrapper dropdown-manufacturer__wrapper-all">
          <div class="row">
            <template v-for="(item) in manufacturer">
              <div class="col-12 col-lg-6 pr-0 mb-12" v-if="item.priority === 0">
                <div
                    @click="manufacturerClick(item)"
                    class="dropdown-manufacturer__item">
                  {{ item.name }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./dropdown-manufacturer.component.ts"></script>
