<style src="./not-data.component.scss" lang="scss"></style>
<template>
  <div class="not-data p-40">
    <div class="not-data__icon">
      <img src="@/assets/images/icons/info-phone.svg" alt="icon">
    </div>
    <div class="not-data__text mt-10">
      <slot></slot>
    </div>
  </div>
</template>
<script src="./not-data.component.ts"></script>
