<style src="./organization-table.component.scss" lang="scss"></style>

<template>
  <div class="organization-table">
    <v-card class="h-container">
      <div class="organization-table__input-wrapper">
        <div class="row">
          <div class="col-12 col-lg-4">
            <input-dropdown-component
                ref="selectOrganizationCategory"
                :placeholder="locale.categoryPlaceholder"
                @selectItem="selectOrganizationCategory"
                :label="locale.category"
                width="100%"
                :data="category"/>
<!--            <div class="organization-table__input-group">-->
<!--              <label class="v-label  mb-4">{{ locale.category }}</label>-->
<!--              <v-select-->
<!--                  ref="selectOrganizationCategory"-->
<!--                  :error="validation.category"-->
<!--                  @select="selectOrganizationCategory"-->
<!--                  :placeholder="locale.categoryPlaceholder"-->
<!--                  :options="category"-->
<!--              />-->
<!--            </div>-->
          </div>
          <div class="col-12 col-lg-4">
            <input-dropdown-component
                class="organization-table__input-group-two"
                ref="selectOrganization"
                :placeholder="locale.organizationPlaceholder"
                @selectItem="selectOrganization"
                :label="locale.organization"
                width="100%"
                :disabled="organization.length===0"
                :data="organization"/>
<!--            <div class="organization-table__input-group organization-table__input-group-two">-->
<!--              <label class="v-label  mb-4">{{ locale.organization }}</label>-->
<!--              <v-select-->
<!--                  ref="selectOrganization"-->
<!--                  :error="validation.organization"-->
<!--                  @select="selectOrganization"-->
<!--                  :placeholder="locale.organizationPlaceholder"-->
<!--                  :options="organization"-->
<!--                  :disabled="!organization.length"-->
<!--              />-->
<!--            </div>-->
          </div>
          <div class="col-12 d-lg-block d-none" v-if="organizationInfo?.length>0">
            <table-component class="mt-40" id="table">
              <thead>
              <tr>
                <th class="w-20 text-center">{{ locale.number }}</th>
                <th>{{ locale.name }}</th>
                <th class="text-left">{{ locale.address }}</th>
                <th>{{ locale.bankAccountCode }}</th>
                <th>{{ locale.tin }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in organizationInfo" :key="index">
                <!--              <th>{{ determineNumber(index) }}</th>-->
                <th class="text-center">{{ item.phone }}</th>
                <th>{{ item.organizationName }}</th>
                <th>{{ item.street }} {{ item.address }}</th>
                <th>{{ item.bankAccount }}</th>
                <th>{{ item.organizationVoin }}</th>
              </tr>
              </tbody>
            </table-component>
            <v-pagination
                ref="pagination"
                :limit="pageSize"
                :dataCount="totalCountOrganizationInfo"
                :page="pageNumber"
                @changePage="changePage"
                class="mt-40 mb-100  "/>
          </div>
        </div>
      </div>
    </v-card>
    <div class="d-lg-none d-block" v-if="organizationInfo?.length>0">
      <organization-mobile-component :organizations="organizationInfo"/>
      <v-pagination
          ref="paginationMobile"
          :limit="pageSize"
          :dataCount="totalCountOrganizationInfo"
          :page="pageNumber"
          @changePage="changePage"
          class="mt-40 mb-100  "/>
    </div>

  </div>
</template>

<script src="./organization-table.component.ts"></script>
