import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
  name: 'table-component',
})

export default class TableComponent extends Vue {

}
