import 'reflect-metadata';
import {locale} from '@/assets/db/db.conf.ts';
import {Options, Vue} from 'vue-class-component';
import AccordionComponent from '@/core/shared/accordion/accordion.component.vue';
import AccordionItemComponent from '@/core/shared/accordion-item/accordion-item.component.vue';

@Options({
    name: 'organization-mobile-component',
    props: {
        organizations: {
            type: Array,
        }
    },
    components: {
        AccordionComponent,
        AccordionItemComponent,
    },

})

export default class OrganizationMobileComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.searchOfficesClassifier) {
                this.locale = res.searchOfficesClassifier;
            }
        });
    }
}