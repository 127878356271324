<style src="./organization-mobile.component.scss" lang="scss"></style>

<template>
  <div class="organization-mobile mt-10">
    <div class="row">
      <div class="col-12">
        <accordion-component>
          <v-card class="mb-10" v-for="(item, index) in organizations" :key="index">
            <accordion-item-component>
              <template v-slot:title>
                <div class="information">
                  <div class="information__title">
                    {{ locale.name }}
                  </div>
                  <div class="information__description">
                    {{ item.organizationName }}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.number }}
                  </div>
                  <div class="information__description">
                    {{ item.phone }}
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="information mt-20">
                  <div class="information__title">
                    {{ locale.address}}
                  </div>
                  <div class="information__description">
                    {{ item.street }} {{ item.address }}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.bankAccountCode }}
                  </div>
                  <div class="information__description">
                    {{ item.bankAccount }}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.tin }}
                  </div>
                  <div class="information__description">
                    {{ item.organizationVoin }}
                  </div>
                </div>

              </template>
            </accordion-item-component>
          </v-card>
        </accordion-component>
      </div>
    </div>
  </div>
</template>

<script src="./organization-mobile.component.ts"></script>