<style src="./service-subtitle.component.scss" lang="scss"></style>

<template>
  <div class="service-subtitle">
    <slot></slot>
    <v-divider class="mt-24 mb-26"/>
  </div>
</template>

<script src="./service-subtitle.component.ts"></script>
