<style src="./search-offices-classifier-service.component.scss" lang="scss"></style>

<template>
  <div class="search-offices-classifier mb-100">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-12">
          <section class="mt-20">
              <organization-table-component/>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./search-offices-classifier-service.component.ts"></script>
