import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'service-subtitle-component',
})

export default class ServiceSubtitleComponent extends Vue {

}
